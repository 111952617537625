<template>
  <b-card
    :title="name"
    bg-variant="dark"
    text-variant="white"
    class="text-center"
  >
    <b-card-text class="text-center">
      <b-icon-bookmark-star-fill
          v-if="winning"
          variant="success"
          font-scale="4"
          class="winning-badge position-absolute"
      ></b-icon-bookmark-star-fill>
      <b-icon-award
          v-if="mostTricks"
          variant="warning"
          font-scale="3"
          class="most-tricks-award position-absolute"
      ></b-icon-award>
      <h1>
        <b-badge pill variant="light">{{ totalPoints }}</b-badge>
      </h1>
      <hand
        v-for="hand in hands"
        :key="`player-${id}-hand-${hand.roundId}`"
        :cards="hand.cards"
        :round-id="hand.roundId"
        :player-id="id"
        :bid="hand.bid"
        :tricks="hand.tricks"
      ></hand>
      <hand
        v-if="showNextHand"
        :round-id="hands.length + 1"
        :player-id="id"
        :cards="cardsInHand[hands.length]"
      ></hand>
    </b-card-text>
  </b-card>
</template>

<script>
import Hand from "./Hand.vue";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    Hand,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    hands: {
      type: Array,
    },
  },
  data() {
    return {
      //
    };
  },
  watch: {
    winning(winning) {
      if (winning) {
        this.$emit('winning');
      }
    },
  },
  computed: {
    ...mapState(["cardsInHand"]),
    ...mapGetters(["pointsForHand", "winningPlayerIds", "mostTricksPlayerIds", "openRoundId"]),
    totalPoints() {
      return this.calculatePointsAtHand(this.hands.length);
    },
    winning() {
      return this.winningPlayerIds.some((playerId) => playerId === this.id);
    },
    mostTricks() {
      return this.mostTricksPlayerIds.some((playerId) => playerId === this.id);
    },
    showNextHand() {
      if (this.hands.length < 1) return true;
      if (this.hands.length === this.cardsInHand.length) return false;
      const lastHand = this.hands[this.hands.length - 1];
      if (lastHand.bid < 0 || lastHand.tricks < 0) return false;
      if (this.openRoundId === this.hands.length + 1) return true;
      return false;
    },
  },
  methods: {
    calculatePointsAtHand(roundId) {
      return this.hands
        .filter((hand) => hand.roundId <= roundId && hand.tricks >= 0)
        .reduce((accumulator, hand) => {
          return accumulator + this.pointsForHand(this.id, hand.roundId);
        }, 0);
    },
  },
};
</script>

<style lang="scss">
.winning-badge {
  top: -10px;
  right: 10px;
}
.most-tricks-award {
  top: 5px;
  left: 0px;
}
</style>
