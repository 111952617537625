<template>
  <div id="game">
    <canvas id="confetti"></canvas>
    <b-input-group prepend="Player Name" class="my-3">
      <b-form-input v-model="newPlayerName"></b-form-input>
      <b-input-group-append>
        <b-button @click="add" variant="outline-primary">Add</b-button>
      </b-input-group-append>
    </b-input-group>
    <h2>{{ overUnder }}</h2>
    <player-progress v-for="player in players" :key="player.id" :id="player.id" :name="player.name"></player-progress>
    <b-card-group deck>
      <player
        v-for="player in players"
        :key="player.id"
        :id="player.id"
        :name="player.name"
        :hands="handsForPlayer(player.id)"
        @winning="confetti"
      ></player>
    </b-card-group>
  </div>
</template>

<script>
import ConfettiGenerator from "confetti-js";
import Player from "./Player.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import PlayerProgress from "./PlayerProgress";
export default {
  components: {
    PlayerProgress,
    Player,
  },
  data() {
    return {
      newPlayerName: "",
      confettiSettings: {
        target: 'confetti',
        respawn: false,
        rotate: true,
        max: 300,
        clock: 50,
        start_from_edge: true,
      },
    };
  },
  methods: {
    ...mapActions(["addPlayer"]),
    add() {
      if (this.newPlayerName === "") return;
      this.addPlayer({ id: this.nextPlayerId, name: this.newPlayerName });
      this.newPlayerName = "";
    },
    confetti() {
      const confetti = new ConfettiGenerator(this.confettiSettings);
      confetti.render();
    },
  },
  computed: {
    ...mapState(["players", "hands"]),
    ...mapGetters([
      "playersCount",
      "handsForPlayer",
      "nextPlayerId",
      "overUnder",
    ]),
  },
};
</script>

<style lang="scss">
#confetti {
  position: absolute;
  width: 100vw;
  height: 100vh;
  margin-top: -15px;
}
</style>
