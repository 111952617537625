<template>
  <div v-if="edit" class="text-center">
    <b-button-group class="d-block text-center mb-1">
      <b-button
        v-for="(card, index) in cards + 1"
        @click="setBid(index)"
        :key="`bid-${index}`"
        :pressed="bid === index"
        variant="secondary"
        size="md"
      >
        {{ index }}
      </b-button>
    </b-button-group>
    <b-button-group v-if="bid >= 0" class="d-block text-center mb-1">
      <b-button
        v-for="(value, index) in tricksLeft + 1"
        @click="setTricks(index)"
        :key="`trick-${index}`"
        :pressed="tricks === index"
        variant="primary"
        size="md"
      >
        {{ index }}
      </b-button>
    </b-button-group>
  </div>
  <b-avatar
    v-else
    button
    :variant="bonus ? 'success' : 'warning'"
    :text="`${pointsForHand(playerId, roundId)}`"
    @click="editHand"
    class="mb-3 mr-1"
  ></b-avatar>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  props: {
    roundId: {
      type: Number,
      default: 1,
    },
    playerId: {
      type: Number,
      default: 0,
    },
    cards: {
      type: Number,
      default: 0,
    },
    bid: {
      type: Number,
      default: -1,
    },
    tricks: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      edit: true,
    };
  },
  computed: {
    ...mapState(["cardsInHand"]),
    ...mapGetters(["pointsForHand", "tricksForRound"]),
    tricksLeft() {
      return this.cards - this.tricksForRound(this.roundId);
    },
    bonus() {
      return this.bid === this.tricks;
    },
  },
  methods: {
    ...mapActions(["commitHand"]),
    editHand() {
      this.edit = true;
    },
    commit(bid, tricks) {
      this.commitHand({
        roundId: this.roundId,
        playerId: this.playerId,
        cards: this.cards,
        bid: bid,
        tricks: tricks,
      });
    },
    setBid(bid) {
      this.commit(bid, this.tricks);
    },
    setTricks(tricks) {
      this.commit(this.bid, tricks);
      this.edit = false;
    },
  },
};
</script>
