<template>
  <div>
    <p class="mb-0"><small>{{ name }}</small></p>
    <div :style="`width: ${progress}%;`" style="height: 10px;" class="bg-primary mb-1"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters(["winningAmountOfPoints", "pointsForPlayer"]),
    points() {
      return this.pointsForPlayer(this.id)
    },
    progress() {
      if (this.points === 0) {
        return 0;
      }
      return (this.points / this.winningAmountOfPoints) * 100
    }
  },
};
</script>
