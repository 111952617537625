import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    players: [],
    hands: [],
    cardsInHand: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4,
      3, 2, 1,
    ],
  },
  mutations: {
    addPlayer(state, payload) {
      state.players.push({ id: payload.id, name: payload.name });
    },
    commitHand(state, payload) {
      let hand = state.hands.find((hand) => {
        return (
          hand.playerId === payload.playerId && hand.roundId === payload.roundId
        );
      });
      if (hand) {
        hand.bid = payload.bid;
        hand.tricks = payload.tricks;
      } else {
        state.hands.push({
          roundId: payload.roundId,
          playerId: payload.playerId,
          cards: payload.cards,
          bid: payload.bid,
          tricks: payload.tricks,
        });
      }
    },
  },
  actions: {
    addPlayer: ({ commit }, payload) => commit("addPlayer", payload),
    commitHand: ({ commit }, payload) => commit("commitHand", payload),
  },
  getters: {
    playersCount: (state) => {
      return state.players.length;
    },
    playerById: (state) => (id) => {
      return state.players.find((player) => player.id === id);
    },
    handsForPlayer: (state) => (id) => {
      return state.hands
        .filter((hand) => hand.playerId === id)
        .sort((a, b) => a.id - b.id);
    },
    pointsForHand: (state) => (playerId, roundId) => {
      const hand = state.hands.find((hand) => {
        return hand.playerId === playerId && hand.roundId === roundId;
      });
      const bonus = hand.bid === hand.tricks;
      return bonus ? 10 + hand.tricks : hand.tricks;
    },
    tricksForRound: (state) => (roundId) => {
      return state.hands
        .filter((hand) => hand.roundId === roundId)
        .reduce((accumulator, hand) => {
          return accumulator + (hand.tricks > 0 ? hand.tricks : 0);
        }, 0);
    },
    nextPlayerId: (state) => {
      return state.players.length + 1;
    },
    points: (state, getters) => {
      const playersWithPoints = state.players.filter((player) => {
        return getters.handsForPlayer(player.id).length > 0;
      });
      if (playersWithPoints.length < 1) {
        return [];
      }
      return playersWithPoints
        .map((player) => {
          return {
            playerId: player.id,
            points: getters
              .handsForPlayer(player.id)
              .reduce((accumulator, hand) => {
                return (
                  accumulator + getters.pointsForHand(player.id, hand.roundId)
                );
              }, 0),
          };
        })
        .sort((a, b) => b.points - a.points);
    },
    winningPlayerIds: (state, getters) => {
      if (getters.points.length < 1) {
        return [];
      }
      const mostPoints = getters.points[0].points;
      return getters.points
        .filter((player) => {
          return player.points >= mostPoints;
        })
        .map((player) => {
          return player.playerId;
        });
    },
    mostTricksPlayerIds: (state) => {
      if (state.hands.length < 1) {
        return [];
      }
      const mostTricks = [... state.hands].sort((a, b) => {
        return a.tricks - b.tricks;
      }).reverse()[0].tricks;
      const playerIdsWithMostTricks = state.hands.filter(hand => {
        return hand.tricks === mostTricks;
      }).map(hand => {
        return hand.playerId;
      })
      return [... new Set(playerIdsWithMostTricks)];
    },
    openRoundId: (state) => {
      const currentRound = state.cardsInHand.findIndex((round, index) => {
        return (
          state.hands.filter((hand) => {
            return (
              hand.roundId === index + 1 && hand.bid >= 0 && hand.tricks >= 0
            );
          }).length < state.players.length
        );
      });
      return currentRound + 1;
    },
    overUnder: (state, getters) => {
      const currentRoundHands = state.hands.filter(
        (hand) => hand.roundId === getters.openRoundId
      );
      const bids = currentRoundHands.reduce((accumulator, hand) => {
        return accumulator + hand.bid;
      }, 0);
      if (currentRoundHands.length < 1) {
        return 0;
      }
      return bids - currentRoundHands[0].cards;
    },
    winningAmountOfPoints: (state, getters) => {
      if (getters.winningPlayerIds.length < 1) {
        return 0
      }
      const firstWinningPlayerId = getters.winningPlayerIds[0]
      const player = getters.points.find((entry) => {
        return entry.playerId === firstWinningPlayerId
      })
      return player.points > 0 ? player.points : 0
    },
    pointsForPlayer: (state, getters) => (playerId) => {
      if (getters.points.length < 1) {
        return 0
      }
      const player = getters.points.find((entry) => {
        return entry.playerId === playerId
      })
      if (typeof player === 'undefined') {
        return 0
      }
      return player.points > 0 ? player.points : 0
    },
  },
});
